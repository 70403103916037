import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import 'react-photoswipe/lib/photoswipe.css';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route  path="/"
            element={<Home />}
          />
          <Route path="/about"
            element = {<About />}
          />
          <Route  path="/services"
            element={<Services />}
          />
          <Route  path="/faq"
            element={<Faq />}
          />
          <Route  path="/gallery"
            element={<Gallery />}
          />
          <Route  path="/contact"
            element={<Contact />}
          />
        </Routes>
      </Layout>
    </Router>
  );
}

function Nav(props) {
  return (
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <Link to="/" class="navbar-brand">
          <img alt="Logo" src={process.env.PUBLIC_URL + "/new_logo.png"}/>
        </Link>
      </div>

      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav navbar-right">
          <li><Link to="/about">About</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/faq">FAQ</Link></li>
          <li><Link to="/gallery">Gallery</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </div>
  )
}

function Footer() {
  return (
    <footer class="footer">
      © Chase The Pack, Inc. 2021
    </footer>
  )
}

function Layout(props) {
  return (
    <html lang="en">
      <body>
        <nav class="navbar navbar-default navbar-fixed-top">
          <Nav route_to={props.route_to} />
        </nav>

        <div class="main container-fluid">
          <div class="content-background">
            <div class="overlay"></div>
          </div>
          <div class="content-container">
            {props.children}
          </div>
        </div>

        <Footer />
      </body>
    </html>
  );
}

function Home() {
  return (
    <div class="container vertical-center pad-for-footer">
      <div class="jumbotron full-height text-center">
        <h1>Dog Walking for Melrose and Stoneham, MA</h1>
        <p>We offer best-in-class services for your pets such as dog walking, daycare, and overnight stays</p>
        <p class="name-unavailable">Currently limited availability for all services, please email to inquire about availability.</p>
        <p><Link class="btn btn-primary btn-lg" to="/services" role="button">Learn More</Link></p>
      </div>
    </div>
  )
}

function About() {
  return (
    <>
      <div class="content">
        <div class="container">
          <div class="row">
            <img class="col-sm-4" src={process.env.PUBLIC_URL + "/turtle-vert.png"} alt=''/>
            <div class="col-sm-8 blurb">
              <p>
                My love for animals started very early in life.
                Growing up, I had dogs, fish, turtles, and birds, which sparked my interest for working with animals and advocating for their health and safety as I got older.
                In high school, I interned at the Woods Hole Science Aquarium, where I prepped meals for the animals, fed them, and answered questions from visitors.
                My first experience dealing with animal safety was overseeing a lobster touch tank; many visitors ignored the rules posted around the tank and threaten the lobsters' safety.
                I felt so bad for them that one morning, I cut the lobsters' bands and released them back into the wild.
                Then, in 2004, I worked with the seals and sea lions at the New England Aquarium in Boston.
                Although I mostly helped clean and prep food, my favorite days were hanging with the seals in the summer.
              </p>

              <p>
                Over the years, I worked other jobs that did not involve animals, and none of them made me excited to go to work everyday.
                Then, one day, I made a big decision – if I'm going to work so much, I might as well do something I love.
                This led to my first job in dog walking in 2013.
                In my early years as a dog walker, I gained a lot of experience caring for so many great dogs and learning how to deal with all their different personalities.
                It was no surprise that getting to walk and play with each and every one of them filled my days with happiness and affection.
                Four years later, I decided to make dog-walking my career and to start my own business, Chase The Pack.
              </p>

              <p>
                Work is not the only time I am surrounded by animals.
                I share my love for animals with my two kids.
                We have had a wide range of pets at home from dogs and cats, to hedgehogs and guinea pigs.
                When I am not working, I enjoy running obstacle course racing and small road races with the New England Spahtens, rucking with Carry the Fallen, wrangling for the 501st, or finding new adventures while traveling with my family.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Services() {
  return (
    <div class="content">
      <div class="container">
        <div class="alert alert-warning" role="alert">All walks are only available Monday-Friday! </div>
                
        <ul class="blurb col-xs-10 col-xs-offset-1">
          <li>
            <div class="service">
              <p class="name">15-min Walk - $15</p>
              <p class="detail">
                A short walk for dogs that only need to stretch their legs and take a bathroom break
              </p>
            </div>
          </li>

          <li>
            <div class="service">
              <p class="name">30-min Walk - $22</p>
              <p class="detail">
                My most popular option.
                Your pup gets out for 30-min of walking and playtime.
                Whenever appropriate, I like to walk a few dogs together for socialization.
              </p>
            </div>
          </li>

            <li>
              <div class="service">
                <p class="name">60-min Walk - $32</p>
                <p class="detail">
                  This walk is for the dog with just a little more energy.
                  It allows for more playtime with friends or a change of scenery such as an occasional hike or time to play in the snow.
                </p>
              </div>
            </li>

            <li>
              <div class="service">
                <p class="name">½-Day - $40</p>
                <p class="detail">
                  Some pups can be nervous or destructive when left alone for a long period of time.
                  This option gives them 2.5+ hours of walking and play as they join me on my awesome, fun-filled day.
                  These spots are limited, and your pup must be good around other dogs and riding in the car.
                  By the time your pup gets home, he or she will be happy and exhausted.
                </p>
              </div>
            </li>

            <li>
              <div class="service">
                <p class="name">Full Day - $50</p>
                <p class="details">
                  Your pup will have a full day of adventure!
                  As with the half day option, your pup must be good around other dogs and riding in the car.
                </p>
              </div>
            </li>
    
              <li>
              <div class="service">
                <p class="name">Private walk - additional $5</p>
                <p class="details">
                  For a private walk - just your dog - add $5 to the price of any length walk.  Limited availability.
                </p>
              </div>
            </li>

            <li>
              <div class="service">
                <p class="name">Cat Visits - $15</p>

                <p class="detail">
                  Good for the kitty who needs a litter change, medication, or just a quick check-up.
                </p>
              </div>
            </li>

            <li>
              <div class="service">
                <p class="name">Overnight Stay - $100</p>
                <p class="name"> Current walking clients only</p>
                <p class="detail">
                  Whether for business or pleasure, everyone needs to get away once in a while.
                  This can be stressful on your pup.
                  By having me come stay with them in your home, it takes some of that stress away.
                  They get to stay in their home, with their bed, their food, their toys, and with someone they know.
                  I am easily reachable so you can check in on them anytime.
                  Additional fees may apply for towns other than Melrose, Wakefield, and Stoneham.
                </p>
              </div>
            </li>
        </ul>
      </div>
    </div>
  )
}

function Faq() {
  return (
    <div class="content">
      <div class="container">
        <ul class="blurb col-xs-10 col-xs-offset-1">
          <li class="qa-block">
            <p class="question">Do you do overnights?</p>

            <p class="answer">
              I do overnights in the client's home.
              It is less stressful on the pets.
              They have everything they need and most dogs do better in their own home as opposed to a boarding situation.
            </p>
          </li>

          <li class="qa-block">
            <p class="question">Do you walk in bad weather?</p>

            <p class="answer">
              I walk in all kinds of weather.
              I work for many essential employees and if I need to be there, I will.
              As long as it is safe for myself and your pup to be out in the elements, we will be.
              If there is an issue with severe wind chill or lightning, I will contact you and shorten the visit or play inside after a potty break.
            </p>
          </li>

          <li class="qa-block">
            <p class="question">What is your booking/cancellation policy?</p>

            <p class="answer">
              In general the sooner I know, the better. Booking at the beginning of the week is the best, or even the night before.
              I can't always promise a spot if you book the day of, but I will always do my best.
              I don't currently charge for cancellations, but if you can cancel by 9am that would allow me to plan my day.
            </p>
          </li>

          <li class="qa-block">
            <p class="question">How do we get started?</p>

            <p class="answer">
              The best way to start is by sending me an email to <a href="mailto:chasethepackdogwalking@gmail.com?Subject=Getting%20Started">chasethepackdogwalking@gmail.com</a> and telling me all
              about you and your pup and what you are looking for.
              I will get back to you ASAP. The next step is for me to meet you and your pup and if we are a good fit for each other we can set up some visits and go from there.
            </p>
          </li>

          <li class="qa-block">
            <p class="question">Are you insured?</p>

            <p class="answer">
              Yes! I am currently insured with Pet Sitters Associates.
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

function Gallery() {
  let items = [
    {src: process.env.PUBLIC_URL + '/gallery/1.png', w: 3264, h: 2448},
    {src: process.env.PUBLIC_URL + '/gallery/3.png', w: 1452, h: 1936},
    {src: process.env.PUBLIC_URL + '/gallery/4.png', w: 1452, h: 1936},
    {src: process.env.PUBLIC_URL + '/gallery/7.png', w: 1452, h: 1936},
    {src: process.env.PUBLIC_URL + '/gallery/8.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/9.png', w: 2016, h: 1512},
    {src: process.env.PUBLIC_URL + '/gallery/10.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/11.png', w: 3264, h: 2448},
    {src: process.env.PUBLIC_URL + '/gallery/12.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/13.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/14.png', w: 1512, h: 1512},
    {src: process.env.PUBLIC_URL + '/gallery/16.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/17.png', w: 2016, h: 1512},
    {src: process.env.PUBLIC_URL + '/gallery/18.png', w: 1452, h: 1936},
    {src: process.env.PUBLIC_URL + '/gallery/20.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/21.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/22.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/23.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/24.png', w: 1691, h: 1269},
    {src: process.env.PUBLIC_URL + '/gallery/25.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/26.png', w: 1280, h: 960},
    {src: process.env.PUBLIC_URL + '/gallery/27.png', w: 3264, h: 2448},
    {src: process.env.PUBLIC_URL + '/gallery/28.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/29.png', w: 3264, h: 2448},
    {src: process.env.PUBLIC_URL + '/gallery/30.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/31.png', w: 3264, h: 2448},
    {src: process.env.PUBLIC_URL + '/gallery/32.png', w: 3264, h: 2448},
    {src: process.env.PUBLIC_URL + '/gallery/33.png', w: 1802, h: 2204},
    {src: process.env.PUBLIC_URL + '/gallery/34.png', w: 2448, h: 3264},
    {src: process.env.PUBLIC_URL + '/gallery/35.png', w: 2016, h: 1512},
    {src: process.env.PUBLIC_URL + '/gallery/36.png', w: 2448, h: 3264}
  ];

  return (
    <>
      {
        items.map((item) =>
          <a target="_blank" href={item.src}>
            <img src={item.src} width={120} alt=''/>
          </a>
        )
      }
    </>
  )
}

function Contact() {
  return (
    <div class="container vertical-center pad-for-footer">
      <div class="jumbotron full-height text-center">
        <h2>Send us an e-mail at <a href="mailto:chasethepackdogwalking@gmail.com">chasethepackdogwalking@gmail.com</a></h2>
      </div>
    </div>
  )
}



export default App;
